import React, { useState } from 'react'
import * as Material from '@material-ui/lab'
import TextField from '../TextField/TextField'
import CheckBox from '../CheckBox/CheckBox'
import PropTypes from 'prop-types'

import { ComboBoxProps } from './ComboBox.types'

import './ComboBox.scss'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { FormHelperText } from '@material-ui/core'

const ComboBox: React.FC<ComboBoxProps> = (props: ComboBoxProps) => {
  const [val, setVal] = useState<any>(null)
  const [message, setMessage] = useState<any>(
    props.showInitMessage ? props.warnMessage : ''
  )

  const handleChange = (event, value) => {
    setVal(value)
    if (props.onChange !== undefined) {
      props.onChange(event, value)
    }

    setMessage(value != null ? '' : props.warnMessage)
  }

  return !props.multiple ? (
    <Material.Autocomplete
      disabled={props.disabled}
      onChange={handleChange}
      id={props.id}
      options={props.options}
      getOptionLabel={(option) => {
        // @ts-ignore
        return option.label
      }}
      style={props.style || {}}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            variant='outlined'
            label={props.label + (props.mandatory ? ' *' : '')}
          />
          {message !== undefined && message.length > 0 && val == null && (
            <FormHelperText error>{message}</FormHelperText>
          )}
        </div>
      )}
      value={props.value}
    />
  ) : (
    <Material.Autocomplete
      disabled={props.disabled}
      onChange={handleChange}
      multiple
      id={props.id}
      options={props.options}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        // @ts-ignore
        return option.label
      }}
      style={props.style || {}}
      renderOption={(option, { selected }) => {
        // @ts-ignore
        const { label } = option
        return (
          <React.Fragment>
            <CheckBox
              icon={props.icon}
              checkedIcon={props.checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {label || ' '}
          </React.Fragment>
        )
      }}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            variant='outlined'
            label={props.label + (props.mandatory ? ' *' : '')}
          />
          {message !== undefined && message.length > 0 && val == null && (
            <FormHelperText error>{message}</FormHelperText>
          )}
        </div>
      )}
      value={props.value}
    />
  )
}

ComboBox.propTypes = {
  /**
   * const top100Films = [
   { label: 'The Shawshank Redemption', year: 1994 },
   { label: 'The Godfather', year: 1972 }
   ] şeklinde olmalı.
   * */
  options: PropTypes.array,
  /**
   *  checkbox icon
   * */
  icon: PropTypes.object,
  /**
   *  checkbox checked icon
   * */
  checkedIcon: PropTypes.object,
  /**
   *  placeholder
   * */
  placeholder: PropTypes.string.isRequired,
  /**
   *  placeholder
   * */
  label: PropTypes.string.isRequired,
  /**
   *  is multiple?
   * */
  multiple: PropTypes.bool,
  /**
   *  component id
   * */
  id: PropTypes.string,
  /**
   *  Use with showError props. A red text will appears under the component.
   * */
  warnMessage: PropTypes.string,
  /**
   *  if value is true, component color turns to red and shows the warnMessage
   * */
  mandatory: PropTypes.bool,
  /**
   *  if value is true and mandatory is true, component color turns to red and shows the warnMessage while page loading
   * */
  showInitMessage: PropTypes.bool,
  /**
   *  if value is true, component can't edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  one of the value of options.
   * */
  value: PropTypes.object,
  /**
   *  function --> const change = (event, selectedValue) => {...}
   * */
  onChange: PropTypes.func
}

ComboBox.defaultProps = {
  icon: <CheckBoxOutlineBlankIcon fontSize='small' />,
  checkedIcon: <CheckBoxIcon fontSize='small' />,
  id: 'checkboxes-tags-demo',
  multiple: true,
  disabled: false,
  mandatory: false,
  warnMessage: '',
  showInitMessage: false
}

export default ComboBox
