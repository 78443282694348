/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { tr } from 'date-fns/locale'
import 'date-fns'
import { DatetimeProps } from './Datetime.types'
import { TimePickerTypes } from './Timepicker.types'
import PropTypes from 'prop-types'
import moment from 'moment'
// Generated with util/create-component.js

export const DatePicker: React.FC<DatetimeProps> = (props: DatetimeProps) => {
  const [selectedDate, setDate] = useState<any>()
  const [warning, setWarning] = React.useState<any>('')

  const onDateChange = (date, value) => {
    setDate(date)
    if (props.handleDateChange !== undefined) {
      props.handleDateChange(date, value, props.id)
    }

    if (!props.mandatory && value != null && value.includes('_')) {
      setWarning(props.warnMessage)
    } else {
      setWarning('')
    }

    if (props.mandatory) {
      if (value == null || value.includes('_')) {
        setWarning(props.warnMessage)
      } else {
        setWarning('')
      }
    }
  }

  React.useEffect(() => {
    setDate(
      props.value != null
        ? moment(props.value, props.format?.toUpperCase())
        : null
    )
  }, [props.value])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
      <KeyboardDatePicker
        {...props}
        autoOk
        clearable
        disableToolbar
        variant={props.type}
        margin='none'
        value={selectedDate}
        invalidLabel=''
        onChange={onDateChange}
        required={props.mandatory}
        error={warning !== undefined && warning.length > 0}
        helperText={warning}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePicker.propTypes = {
  /**
   *  function --> const change = (date, value, id) => {...}
   * */
  handleDateChange: PropTypes.func,
  /**
   * Component Type
   */
  type: PropTypes.oneOf(['inline', 'static', 'dialog']),
  /**
   * Init date for ex: new Date('2020-04-12T00:00:00')
   */
  value: PropTypes.any,
  /**
   *  Date format, for ex: 'dd/MM/yyyy'
   * */
  format: PropTypes.string,
  /**
   *  Component label
   * */
  label: PropTypes.string,
  /**
   *  component id
   * */
  id: PropTypes.string.isRequired,
  /**
   *  if value is true, component can't be edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  min date
   * */
  minDate: PropTypes.any,
  /**
   *  max date
   * */
  maxDate: PropTypes.any,
  /**
   *  if value is true and value is empty, shows warnMessage.
   *  Appears * in the label text
   * */
  mandatory: PropTypes.bool,
  /**
   *  A red text will appears under the component.
   * */
  warnMessage: PropTypes.string
}

DatePicker.defaultProps = {
  id: 'date-picker',
  type: 'inline',
  format: 'dd/MM/yyyy',
  label: 'Tarih',
  disabled: false,
  mandatory: false
}

export const TimePicker: React.FC<TimePickerTypes> = (
  props: TimePickerTypes
) => {
  const [selectedTime, setTime] = useState<any>()
  const [warning, setWarning] = React.useState<any>('')

  const onDateChange = (date, value) => {
    setTime(date)
    if (props.handleTimeChange !== undefined) {
      props.handleTimeChange(date, value, props.id)
    }

    if (!props.mandatory && value != null && value.includes('_')) {
      setWarning(props.warnMessage)
    } else {
      setWarning('')
    }

    if (props.mandatory) {
      if (value == null || value.includes('_')) {
        setWarning(props.warnMessage)
      } else {
        setWarning('')
      }
    }
  }

  React.useEffect(() => {
    setTime(moment(props.value, 'HH:mm'))
  }, [props.value])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
      <KeyboardTimePicker
        {...props}
        id={props.id}
        ampm={false}
        autoOk
        clearable
        margin='none'
        label={props.label}
        variant={props.type}
        views={props.views}
        value={selectedTime}
        emptyLabel=''
        invalidLabel=''
        onChange={onDateChange}
        required={props.mandatory}
        error={warning !== undefined && warning.length > 0}
        helperText={warning}
      />
    </MuiPickersUtilsProvider>
  )
}

TimePicker.propTypes = {
  /**
   *  function --> const change = (date, value, id) => {...}
   * */
  handleTimeChange: PropTypes.func,
  /**
   * Component Type
   */
  type: PropTypes.oneOf(['inline', 'static', 'dialog']),
  /**
   * Array of views to show  ('hours' | 'minutes' | 'seconds')[];
   */
  views: PropTypes.any,
  /**
   *  Component label
   * */
  label: PropTypes.string,
  /**
   *  component id
   * */
  id: PropTypes.string.isRequired,
  /**
   *  if value is true, component can't be edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  if value is true and value is empty, shows warnMessage.
   *  Appears * in the label text
   * */
  mandatory: PropTypes.bool,
  /**
   *  A red text will appears under the component.
   * */
  warnMessage: PropTypes.string,
  /**
   * Init time for ex: 19:05, 10:00 etc
   */
  value: PropTypes.string
}

TimePicker.defaultProps = {
  id: 'time-picker',
  type: 'inline',
  label: 'Saat',
  views: ['hours', 'minutes'],
  disabled: false,
  mandatory: false
}

export default { DatePicker, TimePicker }
