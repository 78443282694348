import React from 'react'
import * as Material from '@material-ui/core'

import { FormControlProps } from './FormControl.types'

import './FormControl.scss'

const FormControl: React.FC<FormControlProps> = (props: FormControlProps) => (
  <Material.FormControl {...props}>
    {props.children}
  </Material.FormControl>
)

export default FormControl
