/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import * as Material from '@material-ui/core'

import { PhoneFieldProps } from './PhoneField.types'

import './PhoneField.scss'
import MaskWrapper from '../common/wrapper/MaskWrapper'
import { ValidationState } from '../common'
import PropTypes from 'prop-types'

const PhoneField: React.FC<PhoneFieldProps> = (props: PhoneFieldProps) => {
  const [val, setVal] = useState<any>('')
  const [warning, setWarning] = React.useState<any>('')
  const [validationState, setValidationState] = useState(
    ValidationState.NotValidated
  )

  React.useEffect(() => {
    const value = props.value || ''
    setVal(value)
    if (value !== '') checkValidation('' + value)
  }, [props.value])

  useEffect(() => {
    setValidationState(
      warning === '' ? ValidationState.Validated : ValidationState.NotValidated
    )
  }, [warning])

  useEffect(() => {
    if (props.onValidationStateChanged !== undefined) {
      props.onValidationStateChanged(validationState)
    }
  }, [validationState])

  const checkValidation = (value) => {
    const valueWithoutMask = value.replace(/\D+/g, '')

    if (!props.mandatory && value.length < 1) {
      setWarning('')
    } else if (props.mandatory && valueWithoutMask.length === 10) {
      setWarning('')
    } else {
      setWarning(props.warnMessage)
    }
  }

  const handleChange = (e) => {
    const maskedValue = e.target.value
    setVal(maskedValue)

    const valueWithoutMask = maskedValue.replace(/\D+/g, '')

    if (props.onChangeText !== undefined) {
      props.onChangeText(e, valueWithoutMask)
    }

    checkValidation(maskedValue)
  }

  return (
    <Material.TextField
      {...props}
      value={val}
      error={warning !== undefined && warning.length > 0}
      helperText={warning}
      variant={props.type}
      required={props.mandatory}
      InputProps={{
        inputComponent: MaskWrapper as any,
        inputProps: {
          placeholderChar: '_',
          pattern: [
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/
          ]
        }
      }}
      onChange={handleChange}
    />
  )
}

export default PhoneField

PhoneField.propTypes = {
  /**
   * Component size
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Component Type
   */
  type: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  placeholder: PropTypes.string,
  /**
   *  placeholder
   * */
  label: PropTypes.string,
  /**
   *  component id
   * */
  id: PropTypes.string,
  /**
   *  A red text will appears under the component.
   * */
  warnMessage: PropTypes.string,
  /**
   *  if value is true and value is empty, shows warnMessage.
   *  Appears * in the label text
   * */
  mandatory: PropTypes.bool,
  /**
   *  if value is true, component can't be edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  value of the component.
   * */
  value: PropTypes.object,
  /**
   *  function --> const change = (event, value) => {...}
   * */
  onChangeText: PropTypes.func,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string
}

PhoneField.defaultProps = {
  label: 'Telefon No',
  size: 'medium',
  warnMessage: 'Lütfen başında 0 olmadan 10 haneli telefon numarasını giriniz.',
  mandatory: false,
  disabled: false,
  id: 'masked-phone-field-input',
  name: 'phoneField',
  type: 'standard'
}
