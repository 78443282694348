import React from 'react'
import MUIDataTable from 'mui-datatables'

import { TableProps } from './Table.types'

import './Table.scss'

const Table: React.FC<TableProps> = (props: TableProps) => (
  <MUIDataTable {...props} />
)

export default Table
