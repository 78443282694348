import React from 'react'
import MaskedInput from 'react-text-mask'

interface MaskWrapperProps {
  inputRef?: (ref: HTMLInputElement | null) => void
  pattern?: any
  placeholderChar?: string
  guide?: boolean
}

const defaultProps: MaskWrapperProps = {
  placeholderChar: '\u2000',
  guide: true
}

const MaskWrapper = (props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      mask={props.pattern}
      placeholderChar={props.placeholderChar}
      guide={props.guide}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
    />
  )
}

MaskWrapper.defaultProps = defaultProps

export default MaskWrapper
