/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import * as Material from '@material-ui/core'
import { ValidationState } from '../common'
import { TextFieldProps } from './TextField.types'
import { checkInputMandatoryAndMinLength } from '../common/functions/validations'

import './TextField.scss'
import PropTypes from 'prop-types'

const getInputProps = (props: TextFieldProps) => {
  const inputProps = {
    ...props.inputProps,
    minLength: 0,
    maxLength: 99999
  }

  if (props.minLength != null && props.minLength > 0) {
    inputProps.minLength = props.minLength
  }

  if (props.maxLength != null && props.maxLength > 0) {
    inputProps.maxLength = props.maxLength
  }
  return inputProps
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const [val, setVal] = useState<any>('')
  const [warning, setWarning] = React.useState<any>('')
  const [validationState, setValidationState] = useState(
    ValidationState.NotValidated
  )

  React.useEffect(() => {
    const value = props.value || ''
    setVal(value)
    if (value !== '') checkValidation(value)
  }, [props.value])

  useEffect(() => {
    setValidationState(
      warning === '' ? ValidationState.Validated : ValidationState.NotValidated
    )
  }, [warning])

  useEffect(() => {
    if (props.onValidationStateChanged !== undefined) {
      props.onValidationStateChanged(validationState)
    }
  }, [validationState])

  const checkValidation = (value) => {
    const isValid = checkInputMandatoryAndMinLength(props, value)

    setWarning(isValid ? '' : props.warnMessage)
  }

  const handleChange = (e) => {
    const value = e.target.value

    setVal(value)

    if (props.onChangeText !== undefined) {
      props.onChangeText(e, value)
    }

    checkValidation(value)
  }

  const inputProps = getInputProps(props)

  return (
    <Material.TextField
      {...props}
      value={val}
      error={warning !== undefined && warning.length > 0}
      helperText={warning}
      onChange={handleChange}
      inputProps={inputProps}
      required={props.mandatory}
    >
      {props.children}
    </Material.TextField>
  )
}

TextField.propTypes = {
  /**
   * Component Type
   */
  type: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  placeholder: PropTypes.string,
  /**
   *  placeholder
   * */
  label: PropTypes.string.isRequired,
  /**
   *  component id
   * */
  id: PropTypes.string,
  /**
   *  A red text will appears under the component.
   * */
  warnMessage: PropTypes.string,
  /**
   *  count of the minimum text length
   * */
  minLength: PropTypes.number,
  /**
   *  count of the maximum text length
   * */
  maxLength: PropTypes.number,
  /**
   *  if value is true and value is empty, shows warnMessage.
   *  Appears * in the label text
   * */
  mandatory: PropTypes.bool,
  /**
   *  if value is true, component can't be edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  function --> const change = (event, value) => {...}
   * */
  onChangeText: PropTypes.func,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * The default value of the `input` element.
   */
  defaultValue: PropTypes.string,
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value: PropTypes.string
}

TextField.defaultProps = {
  warnMessage: 'is required',
  minLength: 0,
  maxLength: 99999,
  mandatory: false,
  disabled: false,
  id: 'textfield-id',
  type: 'standard'
}

export default TextField
