/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import * as Material from '@material-ui/core'
import MaskWrapper from '../common/wrapper/MaskWrapper'
import emailMask from 'text-mask-addons/dist/emailMask'
import { EmailFieldProps } from './EmailField.types'
import { ValidationState } from '../common'
import PropTypes from 'prop-types'

/*
 * Used Text-Mask for formatted values. This is a email field.
 *
 * For instance, when we writes any(exp: a) character and component will be displays like =>  " a@. "
 * Then we writes prefix of email and writes @ automatically passing @ character. Everythins likes . character.
 *
 * Author: Hakan KABASAKAL, 03.06.2020
 */
export const EmailField: React.FC<EmailFieldProps> = (
  props: EmailFieldProps
) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/

  const [val, setVal] = useState<any>('')
  const [warning, setWarning] = React.useState<any>('')
  const [validationState, setValidationState] = useState(
    ValidationState.NotValidated
  )

  React.useEffect(() => {
    const value = props.value || ''
    setVal(value)
    if (value !== '') checkValidation('' + value)
  }, [props.value])

  useEffect(() => {
    setValidationState(
      warning === '' ? ValidationState.Validated : ValidationState.NotValidated
    )
  }, [warning])

  useEffect(() => {
    if (props.onValidationStateChanged !== undefined) {
      props.onValidationStateChanged(validationState)
    }
  }, [validationState])

  const checkValidation = (value) => {
    if (!props.mandatory && value.length < 1) {
      setWarning('')
    } else if (props.mandatory && emailRegex.test(value)) {
      setWarning('')
    } else {
      setWarning(props.warnMessage)
    }
  }

  const handleChange = (e) => {
    const maskedValue = e.target.value
    setVal(maskedValue)

    if (props.onChangeText !== undefined) {
      props.onChangeText(e, maskedValue)
    }

    checkValidation(maskedValue)
  }

  return (
    <Material.TextField
      {...props}
      value={val}
      error={warning !== undefined && warning.length > 0}
      helperText={warning}
      variant={props.type}
      required={props.mandatory}
      InputProps={{
        inputComponent: MaskWrapper as any,
        inputProps: {
          pattern: emailMask
        }
      }}
      onChange={handleChange}
    />
  )
}

export default EmailField

EmailField.propTypes = {
  /**
   * Component size
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Component Type
   */
  type: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  placeholder: PropTypes.string,
  /**
   *  placeholder
   * */
  label: PropTypes.string,
  /**
   *  component id
   * */
  id: PropTypes.string,
  /**
   *  A red text will appears under the component.
   * */
  warnMessage: PropTypes.string,
  /**
   *  if value is true and value is empty, shows warnMessage.
   *  Appears * in the label text
   * */
  mandatory: PropTypes.bool,
  /**
   *  if value is true, component can't be edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  value of the component.
   * */
  value: PropTypes.object,
  /**
   *  function --> const change = (event, value) => {...}
   * */
  onChangeText: PropTypes.func,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string
}

EmailField.defaultProps = {
  label: 'E-Posta Adresi',
  size: 'medium',
  warnMessage: 'Lütfen e-posta adresini kontrol ediniz.',
  mandatory: false,
  disabled: false,
  id: 'masked-email-field-input',
  name: 'emailField',
  type: 'standard'
}
