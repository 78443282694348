import React from 'react'
import * as Material from '@material-ui/core'

import { ListProps } from './List.types'

import './List.scss'

const List: React.FC<ListProps> = ({ children, styles }) => {
  const classes = Material.makeStyles({
    root: { ...(styles || {}) }
  })()

  return <Material.List className={classes.root}>{children}</Material.List>
}

export default List
