/*
 * Is the user's inputted value is valid with the TC Algorithm?
 *
 * More information about of this Algorithm: https://tr.wikipedia.org/wiki/T.C._Kimlik_Numarası
 *
 * Author: Hakan KABASAKAL, 28.05.2020
 */
export const isTCIdentityNoValid = (_TcIdentityNo) => {
  const TcIdentityNo = '' + _TcIdentityNo
  if (!/^[1-9][0-9]{10}$/.test(TcIdentityNo)) {
    return false
  }

  const wrongTCIdentityList = [
    11111111110,
    22222222220,
    33333333330,
    44444444440,
    55555555550,
    66666666660,
    77777777770,
    88888888880,
    99999999990
  ]

  if (wrongTCIdentityList.includes(Number(TcIdentityNo))) {
    return false
  }

  let totalOddIndexes = 0
  let totalEvenIndexes = 0
  let total = 0
  const digit10 = Number(TcIdentityNo.substr(9, 1))
  const digit11 = Number(TcIdentityNo.substr(10, 1))

  for (let i = 0; i < 9; i++) {
    const value = Number(TcIdentityNo.substr(i, 1))

    if (i % 2 === 0) {
      totalOddIndexes += value
    } else {
      totalEvenIndexes += value
    }

    total += value
  }

  const newDigit10 = Math.abs(totalOddIndexes * 7 - totalEvenIndexes) % 10

  return digit10 === newDigit10 && digit11 === (total + newDigit10) % 10
}

/*
 * Is the user's inputted value is valid with minLength and mandatory checks.
 *
 * Author: Hakan KABASAKAL, 05.08.2020
 */
export const checkInputMandatoryAndMinLength = (props, value) => {
  const valueLength = ('' + value).trim().length

  if (props.mandatory && valueLength < 1) {
    return false
  }

  if (!props.mandatory && valueLength < 1) {
    return true
  }

  const isNotValid =
    props.minLength != null &&
    props.minLength > 0 &&
    props.minLength > valueLength

  return !isNotValid
}
