/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import * as Material from '@material-ui/core'
import { NumberFieldProps } from './NumberField.types'

import './NumberField.scss'
import { ValidationState } from '../common'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskWrapper from '../common/wrapper/MaskWrapper'
import PropTypes from 'prop-types'
import { checkInputMandatoryAndMinLength } from '../common/functions/validations'

const NumberField: React.FC<NumberFieldProps> = (props: NumberFieldProps) => {
  const [val, setVal] = useState<any>('')
  const [warning, setWarning] = React.useState<any>('')
  const [validationState, setValidationState] = useState(
    ValidationState.NotValidated
  )

  React.useEffect(() => {
    const value = props.value || ''
    setVal(value)
    if (value !== '') checkValidation('' + value)
  }, [props.value])

  useEffect(() => {
    setValidationState(
      warning === '' ? ValidationState.Validated : ValidationState.NotValidated
    )
  }, [warning])

  useEffect(() => {
    if (props.onValidationStateChanged !== undefined) {
      props.onValidationStateChanged(validationState)
    }
  }, [validationState])

  const checkValidation = (value) => {
    const unMaskedValue = value.replace(/\D+/g, '')

    const isValid = checkInputMandatoryAndMinLength(props, unMaskedValue)

    if (isValid && /^\d+$/.test(unMaskedValue)) {
      setWarning('')
    } else {
      setWarning(
        !props.mandatory && unMaskedValue.length < 1 ? '' : props.warnMessage
      )
    }
  }

  const handleChange = (e) => {
    const maskedValue = e.target.value
    setVal(maskedValue)

    if (props.onChangeText !== undefined) {
      props.onChangeText(e, maskedValue)
    }

    checkValidation(maskedValue)
  }

  const numberMask = createNumberMask({
    prefix: '',
    integerLimit: props.maxLength || null,
    thousandsSeparatorSymbol: props.thousandsSeparatorSymbol,
    includeThousandsSeparator: props.allowThousandsSeparator
  })

  return (
    <Material.TextField
      {...props}
      value={val}
      error={warning !== undefined && warning.length > 0}
      helperText={warning}
      variant={props.type}
      required={props.mandatory}
      InputProps={{
        inputComponent: MaskWrapper as any,
        inputProps: {
          pattern: numberMask
        }
      }}
      onChange={handleChange}
    />
  )
}

export default NumberField

NumberField.propTypes = {
  /**
   * Component Type
   */
  type: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  placeholder: PropTypes.string,
  /**
   *  Component Label
   * */
  label: PropTypes.string,
  /**
   *  component id
   * */
  id: PropTypes.string,
  /**
   *  A red text will appears under the component.
   * */
  warnMessage: PropTypes.string,
  /**
   *  count of the minimum text length
   * */
  minLength: PropTypes.number,
  /**
   *  count of the maximum text length
   * */
  maxLength: PropTypes.number,
  /**
   *  if value is true and value is empty, shows warnMessage.
   *  Appears * in the label text
   * */
  mandatory: PropTypes.bool,
  /**
   *  if value is true, component can't be edit.
   * */
  disabled: PropTypes.bool,
  /**
   *  value of the component.
   * */
  value: PropTypes.object,
  /**
   *  function --> const change = (validationStatus) => {...}
   * */
  onValidationStateChanged: PropTypes.func,
  /**
   *  function --> const change = (event, value) => {...}
   * */
  onChangeText: PropTypes.func,
  /**
   * Name attribute of the `input` element.
   */
  name: PropTypes.string,
  /**
   * Thousands separator symbol('1.000', '1,000', '1 000', '1000')
   */
  thousandsSeparatorSymbol: PropTypes.oneOf(['.', ',', ' ', '']),
  /**
   * Thousands separator symbol is enabled?
   */
  allowThousandsSeparator: PropTypes.bool,
  /**
   * Component size.
   */
  size: PropTypes.oneOf(['small', 'medium'])
}

NumberField.defaultProps = {
  warnMessage: 'Lütfen girilen değeri kontrol ediniz.',
  label: 'label',
  minLength: 0,
  maxLength: 99999,
  mandatory: false,
  disabled: false,
  id: 'masked-number-field-input',
  name: 'number-field',
  size: 'medium',
  thousandsSeparatorSymbol: '.',
  placeholder: undefined,
  allowThousandsSeparator: true
}
