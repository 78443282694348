import React from 'react'
import * as Material from '@material-ui/core'

import { AvatarProps } from './Avatar.types'

import './Avatar.scss'

const Avatar: React.FC<AvatarProps> = ({
  image,
  alt,
  children,
  variant = 'circle',
  width = 64,
  height = 64,
  background = '#004D9E',
  styles = {}
}) => {
  const classes = Material.makeStyles({
    root: {
      ...styles,
      width: width,
      height: height,
      background: background
    }
  })()

  return (
    <Material.Avatar
      className={classes.root}
      alt={alt}
      src={image}
      variant={variant}
    >
      {children}
    </Material.Avatar>
  )
}

export default Avatar
