import React from 'react'
import * as Material from '@material-ui/core'

import { CheckBoxProps } from './CheckBox.types'

import './CheckBox.scss'

const CheckBox: React.FC<CheckBoxProps> = (props: CheckBoxProps) => (
  <Material.Checkbox {...props} />
)

export default CheckBox
