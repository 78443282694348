import * as Material from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

import { LinkProps } from './Link.types'

import './Link.scss'

const Link: React.FC<LinkProps> = (props: LinkProps) => (
  <Material.Link {...props}>{props.children}</Material.Link>
)

export default Link

Link.propTypes = {
  /**
   * The content of the link
   * */
  children: PropTypes.object,
  /**
   *  Override or extend the styles applied to the component. See CSS API below for more details.
   * */
  classes: PropTypes.object,
  /**
   *  The color of the link.
   * */
  color: PropTypes.oneOf([
    'initial',
    'inherit',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
    'primary'
  ]),
  // @ts-ignore
  component: PropTypes.any,
  /**
   * Controls when the link should have an underline.
   **/
  underline: PropTypes.oneOf(['none', 'hover', 'always', 'hover']),
  /**
   *  Applies the theme typography styles.
   * */
  variant: PropTypes.any
}

Link.defaultProps = {
  color: 'primary',
  // @ts-ignore
  component: 'a',
  underline: 'hover',
  variant: 'inherit'
}
