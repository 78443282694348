import React from 'react'
import * as Material from '@material-ui/core'

import { DialogProps } from './Dialog.types'

import './Dialog.scss'

const Dialog: React.FC<DialogProps> = (props: DialogProps) => {
  return <Material.Dialog {...props}>{props.children}</Material.Dialog>
}

export default Dialog
