import React from 'react'
import * as Material from '@material-ui/core'

import { DividerProps } from './Divider.types'

import './Divider.scss'

const Divider: React.FC<DividerProps> = (props: DividerProps) => (
  <Material.Divider {...props} />
)

export default Divider
