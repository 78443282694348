import React from 'react'
import * as Material from '@material-ui/lab'

import { AlertTitleProps } from './AlertTitle.types'

const AlertTitle: React.FC<AlertTitleProps> = (props: AlertTitleProps) => (
  <Material.AlertTitle {...props}>{props.children}</Material.AlertTitle>
)

export default AlertTitle
